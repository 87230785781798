import { NotificationType } from '@local/web-design-system';
import { isEmpty } from 'lodash-es';
import {
    from as observableFrom,
    Subscription,
    toArray,
    mergeMap,
    concatMap,
    switchMap,
    forkJoin,
} from 'rxjs';
import {
    ACTIVITY_TYPES,
    DEFAULT_FILTERS,
    DRILLHOLE_UPDATED_FIELDS,
    MIN_OPERATIONS_FOR_WARNING,
} from 'state-domains/constants';
import { ActivityMap, Row, TableView, createFilterFromSearchTerm } from 'state-domains/domain';
import {
    DeleteRowReferenceRequest,
    DeleteRowReferencesResponse,
    getSortOptionsFromCacheWithDefault,
    processLoadedLithologyRowReferences,
    processLoadedRankedResults,
    processLoadedRowReferences,
    processLoadedSampleResults,
    processLoadedSampleRowReferences,
    processLoadedSamples,
    ReIndexResponse,
} from 'state-domains/domain/drillhole';
import { typeComplete, typeFail, typePending } from 'state-domains/utils';
import { getFiltersForDrillhole } from 'src/components/FilterButton/Filter.utils';
import { getCachedCollarPage } from 'src/components/PaginationControls/PaginationControls.utils';

import {
    LOAD_DRILLHOLES,
    SELECT_COLLARS,
    LOAD_DRILLHOLE,
    LOAD_ROW_REFERENCES,
    SAVE_ROW_REFERENCES,
    FILL_DOWN_SAVE_ROW_REFERENCES,
    SET_CURRENT_DRILLHOLE,
    CLEAR_ROW_REFERENCE_RESPONSE,
    SAVE_DRILLHOLE,
    ADD_DATA_FILE_GROUP,
    UPLOAD_FILE,
    GET_FILES_FOR_GROUP,
    CLEAR_FILES_STATE,
    CLEAR_FILES_FOR_GROUP_LENGTHS_STATE,
    DELETE_FILE,
    CLEAR_SAVE_STATE,
    DOWNLOAD_FILE,
    CLEAR_DOWNLOADED_FILE,
    DELETE_ROW_REFERENCES,
    CREATE_ROWS,
    DUMP_TABLE_DATA,
    CLEAR_DUMP_TABLE_DATA,
    COPY_INTERVALS,
    ADD_DRILLHOLE,
    RE_INDEX,
    CLEAR_ADD_DRILL_HOLE_STATE,
    DELETE_DRILLHOLE,
    LOAD_EMPTY_FILE_GROUPS,
    LOAD_SAMPLES,
    LOAD_SAMPLE,
    CLEAR_EMPTY_FILE_GROUPS,
    SAVE_SPLIT_ROW_ROW_REFERENCES,
    LOAD_SAMPLE_RESULTS,
    LOAD_RANKED_COLUMNS,
    LOAD_LAB_CERTIFICATE_INFO,
    LOAD_SAMPLE_ROW_REFERENCES,
    CLONE_DRILHOLE,
    CLEAR_CLONE_DRILL_HOLE_STATE,
    CLEAR_SAVE_ROW_REFERENCE_STATE,
    CLEAR_DELETE_DRILL_HOLE_STATE,
    UNASSIGN_USER_FROM_DRILLHOLE,
    CLEAR_UNASSIGN_USER_FROM_DHOLE_STATE,
    LOAD_COORDINATES_TABLE,
    LOAD_LITHOLOGY_ROW_REFERENCES,
    EXPORT_COLLAR,
    CUSTOM_EXPORT,
    CLEAR_EXPORT_COLLAR_STATE,
    SELECT_ALL_COLLARS,
    UNSELECT_COLLARS,
    CLEAR_ALL_COLLARS,
    GET_CUSTOM_EXPORTS,
    GET_EXPORT_TEMPLATES,
    ADD_SNACKBAR_NOTIFICATION,
    MERGE_ROW_REFERENCES,
    TOGGLE_SPAN,
    APPLY_EXTERNAL_FILTER,
    FILTER_APPLIED,
    COPY_CELLS,
    COPY_COORDINATE_CELLS,
    SELECT_COLLARS_BY_ID,
    LOAD_DRILLHOLES_WITH_COUNT,
    SET_COLLAR_SEARCH_TERM,
} from '../../../types/actionTypes';
import { selectors as userSelectors } from '../../user/state/selectors';
import { drillholeShim } from '../shim';
import {
    Drillhole,
    RowReference,
    RowReferenceResponse,
    TableDownloadPayload,
    AddDrillHoleResponse,
    FilterObject,
    Sample,
    LabCertificateInfo,
    CloneDrillholeRequest,
    CustomExportInfo,
    ExportTemplateInfo,
} from '../types';

import { selectors as drillHoleSelectors } from './selectors';

const { currentUserId, currentSubscription } = userSelectors;
const { collarSearchTerm } = drillHoleSelectors;

const getFilters = (
    projId: string,
    userId: string,
    currentSubscriptionId: string,
    type: ACTIVITY_TYPES,
) => {
    const filters = getFiltersForDrillhole(projId, userId, currentSubscriptionId, type);
    return isEmpty(filters) ? DEFAULT_FILTERS() : filters;
};

const loadDrillholes =
    (projectId: string, type: ACTIVITY_TYPES) => (dispatch: any, getState: any) => {
        dispatch({ type: typePending(LOAD_DRILLHOLES), payload: projectId });
        const userId = currentUserId(getState());
        const currentSubscriptionId = currentSubscription(getState());
        const dhFilters = getFilters(projectId, userId, currentSubscriptionId.id, type);
        const cachedPageDetails = getCachedCollarPage(
            type,
            userId,
            currentSubscriptionId.id,
            projectId,
        );
        const filters = (dhFilters.filters as FilterObject[]) ?? [];
        const searchTerm = collarSearchTerm(getState());
        if (searchTerm) filters.push(createFilterFromSearchTerm(searchTerm) as any);

        const sortKey = getSortOptionsFromCacheWithDefault(
            userId,
            currentSubscriptionId.id,
            type,
            projectId,
        );
        const { offset: offsetValue, limit: limitValue } = cachedPageDetails;
        return drillholeShim
            .loadDrillholes(
                projectId,
                type,
                dhFilters.activities as string[],
                dhFilters.state as string[],
                filters,
                { sortKey, offset: offsetValue, limit: limitValue },
            )
            .subscribe({
                next: (response: Drillhole[]) => {
                    dispatch({
                        type: typeComplete(LOAD_DRILLHOLES),
                        payload: { response },
                    });
                },
                error: (error: any) => {
                    dispatch({ type: typeFail(LOAD_DRILLHOLES), payload: { error } });
                    dispatch({
                        type: ADD_SNACKBAR_NOTIFICATION,
                        payload: { type: NotificationType.ERROR, message: error },
                    });
                },
            });
    };

const loadDrillholeWithCount =
    (projectId: string, type: ACTIVITY_TYPES) =>
    (dispatch: any, getState: any): Subscription => {
        dispatch({ type: typePending(LOAD_DRILLHOLES_WITH_COUNT) });
        const userId = currentUserId(getState());
        const currentSubscriptionId = currentSubscription(getState());
        const dhFilters = getFilters(projectId, userId, currentSubscriptionId.id, type);
        const cachedPage = getCachedCollarPage(type, userId, currentSubscriptionId.id, projectId);
        const searchTerm = collarSearchTerm(getState());
        const activities = dhFilters.activities as string[];
        const state = dhFilters.state as string[];
        const filters = (dhFilters.filters as FilterObject[]) ?? [];
        if (searchTerm) filters.push(createFilterFromSearchTerm(searchTerm) as any);
        const sortKey = getSortOptionsFromCacheWithDefault(
            userId,
            currentSubscriptionId.id,
            type,
            projectId,
        );
        const { offset: collarOffset, limit: collarLimit } = cachedPage;
        return forkJoin({
            count: drillholeShim.loadDrillholeCount(projectId, type, activities, state, filters),
            items: drillholeShim.loadDrillholes(projectId, type, activities, state, filters, {
                sortKey,
                offset: collarOffset,
                limit: collarLimit,
            }),
        }).subscribe({
            next: (response: any) => {
                const { count, items } = response;
                dispatch({
                    type: typeComplete(LOAD_DRILLHOLES_WITH_COUNT),
                    payload: { count: count.size, items },
                });
            },
            error: (error: any) => {
                dispatch({ type: typeFail(LOAD_DRILLHOLES_WITH_COUNT), payload: { error } });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
    };

const loadDrillhole =
    (drillholeId: string, type: ACTIVITY_TYPES) => (dispatch: any, _getState: Function) => {
        dispatch({ type: typePending(LOAD_DRILLHOLE), payload: drillholeId });
        return drillholeShim.loadDrillhole(drillholeId, type).subscribe({
            next: (response: Drillhole) => {
                dispatch({ type: typeComplete(LOAD_DRILLHOLE), payload: response });
            },
            error: (error: any) => {
                dispatch({ type: typeFail(LOAD_DRILLHOLE), payload: { error } });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
    };

const saveDrillHole =
    (drillHole: any, type: ACTIVITY_TYPES, modalCall = false) =>
    (dispatch: any) => {
        dispatch({ type: typePending(SAVE_DRILLHOLE) });
        return drillholeShim.saveDrillHole(drillHole, type).subscribe({
            next: (response: any) => {
                dispatch({ type: typeComplete(SAVE_DRILLHOLE), payload: response });
            },
            error: (error: any) => {
                dispatch({ type: typeFail(SAVE_DRILLHOLE), payload: { error } });

                if (!modalCall) {
                    dispatch({
                        type: ADD_SNACKBAR_NOTIFICATION,
                        payload: { type: NotificationType.ERROR, message: error },
                    });
                }
            },
        });
    };

const addFileGroup =
    (type: ACTIVITY_TYPES, dataFileGroup: any, header = false) =>
    (dispatch: any) => {
        dispatch({ type: typePending(ADD_DATA_FILE_GROUP) });
        return drillholeShim.addFileGroup(type, dataFileGroup, header).subscribe({
            next: (response: any) => {
                dispatch({ type: typeComplete(ADD_DATA_FILE_GROUP), payload: response });
            },
            error: (error: any) => {
                dispatch({ type: typeFail(ADD_DATA_FILE_GROUP), payload: { error } });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
    };

const createRows =
    (payload: any, isSample: boolean, type: ACTIVITY_TYPES) => (dispatch: any, getState: any) => {
        dispatch({ type: typePending(CREATE_ROWS) });
        return drillholeShim.createRows(payload, type).subscribe({
            next: (response: RowReferenceResponse) => {
                if (isSample) {
                    loadSample(
                        response.RowReference,
                        response,
                        type,
                        CREATE_ROWS,
                        false,
                    )(dispatch, getState);
                } else {
                    dispatch({
                        type: typeComplete(CREATE_ROWS),
                        payload: { response, allState: getState() },
                    });
                }
            },
            error: (error: any) => {
                dispatch({ type: typeFail(CREATE_ROWS), payload: { error } });
            },
        });
    };

const reIndex = (payload: any) => (dispatch: any) => {
    dispatch({ type: typePending(RE_INDEX) });
    return drillholeShim.reIndex(payload).subscribe({
        next: (response: ReIndexResponse) => {
            dispatch({
                type: typeComplete(RE_INDEX),
                payload: { response },
            });
        },
        error: (error: any) => {
            dispatch({ type: typeFail(RE_INDEX), payload: { error } });
            dispatch({
                type: ADD_SNACKBAR_NOTIFICATION,
                payload: { type: NotificationType.ERROR, message: error },
            });
        },
    });
};

const uploadFiles =
    (type: ACTIVITY_TYPES, filesArray: any, dataFileGroupId: string, header = false) =>
    (dispatch: any) => {
        dispatch({ type: typePending(UPLOAD_FILE) });
        const filesList = filesArray.map((item: any) => ({ file: item, dataFileGroupId }));
        observableFrom(filesList)
            .pipe(
                mergeMap(
                    (fileObject: any) => drillholeShim.uploadFile(type, fileObject, header),
                    2,
                ),
            )
            .pipe(toArray())
            .subscribe({
                next: (response: any) => {
                    const error: string[] = [];
                    for (const fileParams of response) {
                        if ((fileParams.file as any).ok === false) {
                            error.push(`${fileParams.name} could not be uploaded.`);
                        }
                    }

                    if (error.length) {
                        dispatch({
                            type: ADD_SNACKBAR_NOTIFICATION,
                            payload: {
                                type: NotificationType.ERROR,
                                message: { message: error.join('\n') },
                            },
                        });
                    }

                    dispatch({
                        type: typeComplete(UPLOAD_FILE),
                        payload: response.map((x: any) => x.file),
                    });
                },
                error: (error: any) => {
                    dispatch({ type: typeFail(UPLOAD_FILE), payload: { error } });
                    dispatch({
                        type: ADD_SNACKBAR_NOTIFICATION,
                        payload: { type: NotificationType.ERROR, message: error },
                    });
                },
            });
    };

const getFilesForGroup =
    (type: ACTIVITY_TYPES, fileGroupId: string, header = false) =>
    (dispatch: any) => {
        dispatch({ type: typePending(GET_FILES_FOR_GROUP) });
        return drillholeShim.getFilesForGroup(type, fileGroupId, header).subscribe({
            next: (response: any) => {
                dispatch({
                    type: typeComplete(GET_FILES_FOR_GROUP),
                    payload: { response, fileGroupId },
                });
            },
            error: (error: any) => {
                dispatch({ type: typeFail(GET_FILES_FOR_GROUP), payload: { error } });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
    };

const getEmptyFileGroups =
    (type: ACTIVITY_TYPES, collarId: string, tableViewId: string) => (dispatch: any) => {
        dispatch({ type: typePending(LOAD_EMPTY_FILE_GROUPS) });
        return drillholeShim.getEmptyFileGroups(type, collarId, tableViewId).subscribe({
            next: (response: any) => {
                dispatch({
                    type: typeComplete(LOAD_EMPTY_FILE_GROUPS),
                    payload: { groups: response, collarId, tableViewId },
                });
            },
            error: (error: any) => {
                dispatch({ type: typeFail(LOAD_EMPTY_FILE_GROUPS), payload: { error } });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
    };

const deleteFile =
    (type: ACTIVITY_TYPES, fileId: string, fileGroup: any, header = false) =>
    (dispatch: any) => {
        dispatch({ type: typePending(DELETE_FILE) });
        return drillholeShim.deleteFile(type, fileId, fileGroup, header).subscribe({
            next: (response: any) => {
                dispatch({ type: typeComplete(DELETE_FILE), payload: response });
            },
            error: (error: any) => {
                dispatch({ type: typeFail(DELETE_FILE), payload: { error } });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
    };

const loadSample =
    (
        samplesReferences: RowReference[],
        response: RowReferenceResponse,
        type: ACTIVITY_TYPES,
        dispatchName: string,
        isInsert = false,
    ) =>
    (dispatch: any, getState: any) =>
        observableFrom(samplesReferences)
            .pipe(
                mergeMap(
                    (rowReference: RowReference) =>
                        drillholeShim.loadSample(rowReference.sample ?? '', type),
                    5,
                ),
            )
            .pipe(toArray())
            .subscribe({
                next: (sampleResponse: Sample[]) => {
                    dispatch({ type: LOAD_SAMPLE, payload: sampleResponse });
                    // isSample, used for passing down to row builder which performs validations accordingly
                    dispatch({
                        type: typeComplete(dispatchName),
                        payload: { response, isInsert, allState: getState(), isSample: true },
                    });
                },
                error: (error: any) => {
                    dispatch({ type: typeFail(dispatchName), payload: { error } });
                    dispatch({
                        type: ADD_SNACKBAR_NOTIFICATION,
                        payload: { type: NotificationType.ERROR, message: error },
                    });
                },
            });

const loadRowReferences =
    (
        collar: string,
        tableView: TableView,
        activity: ActivityMap,
        isSample: boolean,
        isXRF: boolean,
        type: ACTIVITY_TYPES,
        loadSampleResults: boolean,
        dispatchPending = true,
    ) =>
    (dispatch: any, getState: any) => {
        const getParameters = (actionName: string) => {
            const parameters: string[] = [];

            switch (actionName) {
                case LOAD_SAMPLES:
                case LOAD_SAMPLE_RESULTS:
                    parameters.push(collar);
                    break;
                case LOAD_RANKED_COLUMNS:
                    parameters.push(activity.id);
                    break;
                case LOAD_ROW_REFERENCES:
                    parameters.push(collar, tableView.id);
                    break;
                case LOAD_SAMPLE_ROW_REFERENCES:
                    parameters.push(collar, activity.samples ?? '');
                    break;
                case LOAD_LITHOLOGY_ROW_REFERENCES:
                    parameters.push(collar, activity.lithology ?? '');
                    break;
                default:
                    break;
            }

            return parameters;
        };

        if (dispatchPending) {
            dispatch({ type: typePending(LOAD_ROW_REFERENCES) });
        }

        return observableFrom([
            {
                action: drillholeShim.loadRowReferences,
                processResultFunction: processLoadedRowReferences,
                actionName: LOAD_ROW_REFERENCES,
                type,
            },
            ...(tableView.linkedToLithology
                ? [
                      {
                          action: drillholeShim.loadRowReferences,
                          processResultFunction: processLoadedLithologyRowReferences,
                          actionName: LOAD_LITHOLOGY_ROW_REFERENCES,
                          type,
                      },
                  ]
                : []),
            ...(isXRF || isSample
                ? [
                      {
                          action: drillholeShim.loadSamples,
                          processResultFunction: processLoadedSamples,
                          actionName: LOAD_SAMPLES,
                          type,
                      },
                  ]
                : []),
            ...(isXRF && !isSample && !!activity.samples
                ? [
                      {
                          action: drillholeShim.loadRowReferences,
                          processResultFunction: processLoadedSampleRowReferences,
                          actionName: LOAD_SAMPLE_ROW_REFERENCES,
                          type,
                      },
                  ]
                : []),
            ...(isSample && loadSampleResults
                ? [
                      {
                          action: drillholeShim.loadSampleResults,
                          processResultFunction: processLoadedSampleResults,
                          actionName: LOAD_SAMPLE_RESULTS,
                          type,
                      },
                      {
                          action: drillholeShim.loadRankedColumns,
                          processResultFunction: processLoadedRankedResults,
                          actionName: LOAD_RANKED_COLUMNS,
                          type,
                      },
                  ]
                : []),
        ])
            .pipe(
                mergeMap(
                    (subShim: {
                        action: Function;
                        processResultFunction: Function;
                        actionName: string;
                        type: ACTIVITY_TYPES;
                    }) => {
                        const { actionName, processResultFunction, type } = subShim;
                        return subShim.action(
                            ...getParameters(actionName),
                            actionName,
                            processResultFunction,
                            type,
                        );
                    },
                ),
            )
            .pipe(toArray())
            .subscribe({
                next: (response: any) => {
                    dispatch({
                        type: typeComplete(LOAD_ROW_REFERENCES),
                        payload: {
                            response,
                            tableView,
                            allState: getState(),
                            isSample,
                            sampleTable: activity.samples,
                            lithologyTable: activity.lithology,
                            isXRF,
                        },
                    });
                },
                error: (error: any) => {
                    dispatch({
                        type: typeFail(LOAD_ROW_REFERENCES),
                        payload: { error },
                    });
                    dispatch({
                        type: ADD_SNACKBAR_NOTIFICATION,
                        payload: { type: NotificationType.ERROR, message: error },
                    });
                },
            });
    };

const sortRowReferences =
    (
        rowReferences: Record<string, RowReference>,
        collar: Drillhole,
        payload: any,
        isSample: boolean,
    ) =>
    (dispatch: any, getState: any) => {
        dispatch({ type: typePending(SAVE_ROW_REFERENCES) });
        return drillholeShim.sortRowReferences(payload).subscribe({
            next: (response: Record<string, number>) => {
                const RowReference: RowReference[] = [];
                Object.entries(response).forEach(([key, value]) => {
                    if (key in rowReferences) {
                        const rowReference = {
                            ...rowReferences[key],
                            isAddedToIntervalTree: false,
                        };
                        rowReference.index = value;
                        RowReference.push(rowReference);
                    }
                });
                dispatch({
                    type: typeComplete(SAVE_ROW_REFERENCES),
                    payload: {
                        response: { RowReference, Collar: collar },
                        allState: getState(),
                        isInsert: false,
                        isSample,
                        isSorted: true,
                    },
                });
            },
            error: (error: any) =>
                dispatch({ type: typeFail(SAVE_ROW_REFERENCES), payload: { error } }),
        });
    };

const saveRowReference =
    (rowReference: any, type: ACTIVITY_TYPES, isInsert = false, isSample = false) =>
    (dispatch: any, getState: any) => {
        dispatch({ type: typePending(SAVE_ROW_REFERENCES) });
        return drillholeShim.saveRowReference(rowReference, type).subscribe({
            next: (response: RowReferenceResponse) => {
                if (!rowReference._id && isSample) {
                    loadSample(
                        response.RowReference,
                        response,
                        type,
                        SAVE_ROW_REFERENCES,
                        isInsert,
                    )(dispatch, getState);
                } else {
                    dispatch({
                        type: typeComplete(SAVE_ROW_REFERENCES),
                        payload: { response, isInsert, allState: getState(), isSample },
                    });
                }
            },
            error: (error: any) => {
                dispatch({ type: typeFail(SAVE_ROW_REFERENCES), payload: { error } });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
    };

const copyCells =
    (rowReferences: any[], type: ACTIVITY_TYPES, isSample = false) =>
    (dispatch: any, getState: any) => {
        if (rowReferences.length >= MIN_OPERATIONS_FOR_WARNING) {
            dispatch({
                type: ADD_SNACKBAR_NOTIFICATION,
                payload: {
                    type: NotificationType.WARNING,
                    i18nKey: 'longProcessWarning',
                },
            });
        }

        dispatch({ type: typePending(COPY_CELLS) });

        drillholeShim.copyCells(rowReferences, type).subscribe({
            next: (response: RowReferenceResponse) => {
                const rowReferenceCollection = getState().drillhole.rowReferences;
                const samplesToLoad = response.RowReference.filter(
                    (x: RowReference) => !rowReferenceCollection[x.id],
                );
                if (samplesToLoad.length && isSample) {
                    loadSample(samplesToLoad, response, type, COPY_CELLS)(dispatch, getState);
                } else {
                    dispatch({
                        type: typeComplete(COPY_CELLS),
                        payload: { response, allState: getState(), isSample },
                    });
                }
            },
            error: (error: any) => {
                dispatch({ type: typeFail(COPY_CELLS), payload: { error } });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
    };

const copyCoordinateCells =
    (drillholeId: string, coordinatesRows: any[], type: ACTIVITY_TYPES) =>
    (dispatch: any, getState: any) => {
        if (coordinatesRows.length >= MIN_OPERATIONS_FOR_WARNING) {
            dispatch({
                type: ADD_SNACKBAR_NOTIFICATION,
                payload: {
                    type: NotificationType.WARNING,
                    i18nKey: 'longProcessWarning',
                },
            });
        }

        dispatch({ type: typePending(COPY_COORDINATE_CELLS) });

        drillholeShim.copyCoordinateCells(drillholeId, coordinatesRows, type).subscribe({
            next: (_response: Row[]) => {
                loadCoordinatesInfoFromDrillhole(
                    drillholeId,
                    SAVE_ROW_REFERENCES,
                    type,
                )(dispatch, getState);
            },
            error: (error: any) => {
                dispatch({ type: typeFail(COPY_COORDINATE_CELLS), payload: { error } });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
    };

const saveSplitRowRowReference =
    (updatePayload: any, createPayload: any, queryParameters: any, type: ACTIVITY_TYPES) =>
    (dispatch: any, getState: any) => {
        dispatch({ type: typePending(SAVE_SPLIT_ROW_ROW_REFERENCES) });
        return observableFrom([
            {
                payload: updatePayload,
                queryParameters: {},
            },
            {
                payload: createPayload,
                queryParameters,
            },
        ])
            .pipe(
                concatMap((parameters: { payload: any; queryParameters?: any }) =>
                    drillholeShim.saveRowReference(
                        parameters.payload,
                        type,
                        parameters.queryParameters,
                    ),
                ),
            )
            .pipe(toArray())
            .subscribe({
                next: (collection: RowReferenceResponse[]) => {
                    // API Calls are concurrent to ensure collar object on last response is up to date with all changes.
                    const response: RowReferenceResponse = {
                        Collar: collection[1].Collar,
                        RowReference: [
                            ...collection[0].RowReference,
                            ...collection[1].RowReference,
                        ],
                        NextRows: {
                            ...(collection[0].NextRows ?? {}),
                            ...(collection[1].NextRows ?? {}),
                        },
                    };

                    dispatch({
                        type: typeComplete(SAVE_SPLIT_ROW_ROW_REFERENCES),
                        payload: {
                            response,
                            isInsert: true,
                            isSample: false,
                            allState: getState(),
                        },
                    });
                },
                error: (error: any) =>
                    dispatch({ type: typeFail(SAVE_SPLIT_ROW_ROW_REFERENCES), payload: { error } }),
            });
    };

const mergeRowReferences =
    (deletePayload: any, updatePayload: any, type: ACTIVITY_TYPES, isSample: boolean) =>
    (dispatch: any, getState: any) => {
        dispatch({ type: typePending(MERGE_ROW_REFERENCES) });
        return observableFrom([
            {
                action: drillholeShim.deleteRowReferences,
                payload: deletePayload,
            },
            {
                action: drillholeShim.saveRowReference,
                payload: updatePayload,
            },
        ])
            .pipe(
                concatMap((parameters: { action: Function; payload: any }) =>
                    parameters.action(parameters.payload, type),
                ),
            )
            .pipe(toArray())
            .subscribe({
                next: (collection: any[]) => {
                    // API Calls are concurrent to ensure responses go to corresponding calls.
                    // Need to see if we can combine the reducers and reduce computations
                    dispatch({
                        type: typeComplete(DELETE_ROW_REFERENCES),
                        payload: { response: collection[0], allState: getState() },
                    });

                    dispatch({
                        type: typeComplete(SAVE_ROW_REFERENCES),
                        payload: {
                            response: {
                                ...collection[1],
                                NextRows: {
                                    ...(collection[0].NextRows ?? {}),
                                    ...(collection[1].NextRows ?? {}),
                                },
                            },
                            isInsert: false,
                            allState: getState(),
                            isSample,
                            isDeleted: true, // Will trigger reconstruction of all rows
                        },
                    });
                },
                error: (error: any) =>
                    dispatch({ type: typeFail(MERGE_ROW_REFERENCES), payload: { error } }),
            });
    };

const addDuplicateSample =
    (rowReference: any, parent: string, type: ACTIVITY_TYPES) => (dispatch: any, getState: any) => {
        dispatch({ type: typePending(SAVE_ROW_REFERENCES) });
        return drillholeShim.addDuplicateSample(rowReference, parent, type).subscribe({
            next: (response: RowReferenceResponse) => {
                loadSample(
                    response.RowReference,
                    response,
                    type,
                    SAVE_ROW_REFERENCES,
                    true,
                )(dispatch, getState);
            },
            error: (error: any) => {
                dispatch({ type: typeFail(SAVE_ROW_REFERENCES), payload: { error } });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
    };

const deleteRowReferences =
    (deleteRequest: DeleteRowReferenceRequest, type: ACTIVITY_TYPES, isSample: boolean) =>
    (dispatch: any, getState: any) => {
        dispatch({ type: typePending(DELETE_ROW_REFERENCES) });
        return drillholeShim.deleteRowReferences(deleteRequest, type).subscribe({
            next: (response: DeleteRowReferencesResponse) => {
                // Need to see if we can combine the reducers and reduce computations
                // isSample, used for passing down to row builder which performs validations accordingly
                dispatch({
                    type: typeComplete(DELETE_ROW_REFERENCES),
                    payload: { response, allState: getState(), isSample },
                });

                if (response.NextRows) {
                    dispatch({
                        type: typeComplete(SAVE_ROW_REFERENCES),
                        payload: {
                            response: {
                                RowReference: Object.values(response.NextRows),
                                Collar: response.Collar,
                            },
                            allState: getState(),
                            isSample,
                            isDeleted: true, // Will trigger reconstruction of all rows
                        },
                    });
                }
            },
            error: (error: any) => {
                dispatch({ type: typeFail(DELETE_ROW_REFERENCES), payload: { error } });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
    };

const fillDownSaveRowReference =
    (payload: any, id: string, isSample: boolean, type: ACTIVITY_TYPES) =>
    (dispatch: any, getState: any) => {
        dispatch({ type: typePending(FILL_DOWN_SAVE_ROW_REFERENCES) });
        return drillholeShim.fillDownSaveRowReference(payload, id, type).subscribe({
            next: (response: RowReferenceResponse) => {
                dispatch({
                    type: typeComplete(FILL_DOWN_SAVE_ROW_REFERENCES),
                    payload: { response, isSample, allState: getState() },
                });
            },
            error: (error: any) => {
                dispatch({ type: typeFail(FILL_DOWN_SAVE_ROW_REFERENCES), payload: { error } });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
    };

const copyIntervals =
    (payload: any, isSample: boolean, type: ACTIVITY_TYPES) => (dispatch: any, getState: any) => {
        dispatch({ type: typePending(COPY_INTERVALS) });
        return drillholeShim.copyIntervals(payload, type).subscribe({
            next: (response: RowReferenceResponse) => {
                if (isSample) {
                    loadSample(
                        response.RowReference,
                        response,
                        type,
                        COPY_INTERVALS,
                        false,
                    )(dispatch, getState);
                } else {
                    dispatch({
                        type: typeComplete(COPY_INTERVALS),
                        payload: { response, allState: getState() },
                    });
                }
            },
            error: (error: any) => dispatch({ type: typeFail(COPY_INTERVALS), payload: { error } }),
        });
    };

const downloadFile =
    (fileGroupId: string, fileId: string, fileName: string, header = false) =>
    (dispatch: any) => {
        dispatch({ type: typePending(DOWNLOAD_FILE) });
        return drillholeShim.downloadFile(fileGroupId, fileId, fileName, header).subscribe({
            next: (response: any) => {
                dispatch({
                    type: typeComplete(DOWNLOAD_FILE),
                    payload: { response },
                });
            },
            error: (error: any) => {
                dispatch({ type: typeFail(DOWNLOAD_FILE), payload: { error } });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
    };

const clearExportCollarState = () => (dispatch: any) => {
    dispatch({ type: typeComplete(CLEAR_EXPORT_COLLAR_STATE) });
};

const clearDownloadedFile = () => (dispatch: any) => {
    dispatch({ type: typeComplete(CLEAR_DOWNLOADED_FILE) });
};

const clearAddDrillHoleState = () => (dispatch: any) => {
    dispatch({ type: typeComplete(CLEAR_ADD_DRILL_HOLE_STATE) });
};

const clearCloneDrillHoleState = () => (dispatch: any) => {
    dispatch({ type: typeComplete(CLEAR_CLONE_DRILL_HOLE_STATE) });
};

const clearRowReferenceResponse = (dispatch: any) => {
    dispatch({ type: CLEAR_ROW_REFERENCE_RESPONSE });
};

const clearSaveRowReferenceState = (dispatch: any) => {
    dispatch({ type: CLEAR_SAVE_ROW_REFERENCE_STATE });
};

const clearFilesState = () => (dispatch: any) => {
    dispatch({ type: typeComplete(CLEAR_FILES_STATE) });
};

const clearFilesForGroupLengthsState = () => (dispatch: any) => {
    dispatch({ type: typeComplete(CLEAR_FILES_FOR_GROUP_LENGTHS_STATE) });
};

const clearSaveState = () => (dispatch: any) => {
    // Clear save state for row references and drill hole
    dispatch({ type: typeComplete(CLEAR_SAVE_STATE) });
};

const clearDeleteDrillHoleState = () => (dispatch: any) => {
    // Clear save state for row references and drill hole
    dispatch({ type: typeComplete(CLEAR_DELETE_DRILL_HOLE_STATE) });
};

const clearEmptyFileGroup = () => (dispatch: any) => {
    dispatch({ type: typeComplete(CLEAR_EMPTY_FILE_GROUPS) });
};

const selectCollarByIdAndLoadData =
    (id: string, type: ACTIVITY_TYPES, isFiredRef: React.MutableRefObject<boolean>) =>
    (dispatch: any, getState: any) => {
        const { drillholeLoaded } = drillHoleSelectors;
        const isLoaded = drillholeLoaded(id)(getState());

        isFiredRef.current = !isLoaded;
        if (!isLoaded) {
            dispatch({ type: typePending(LOAD_DRILLHOLE) });
            return drillholeShim.loadDrillhole(id, type).subscribe({
                next: (response: Drillhole) => {
                    dispatch({ type: typeComplete(LOAD_DRILLHOLE), payload: response });
                    dispatch({
                        type: SELECT_COLLARS,
                        payload: [response],
                    });
                },
                error: (error: any) => {
                    dispatch({ type: typeFail(LOAD_DRILLHOLE), payload: { error } });
                    dispatch({
                        type: ADD_SNACKBAR_NOTIFICATION,
                        payload: { type: NotificationType.ERROR, message: error },
                    });
                },
            });
        }
        return dispatch({
            type: SELECT_COLLARS_BY_ID,
            payload: [id],
        });
    };

const selectAllDrillHoles = () => (dispatch: any, _getState: any) => {
    dispatch({ type: typeComplete(SELECT_ALL_COLLARS) });
};

const clearAllDrillHoles = () => (dispatch: any, _getState: any) => {
    dispatch({ type: typeComplete(CLEAR_ALL_COLLARS) });
};
const selectCollars = (drillHoles: Drillhole[]) => (dispatch: any) =>
    dispatch({
        type: SELECT_COLLARS,
        payload: drillHoles,
    });

const unSelectCollars = (collarIds: string[]) => (dispatch: any) => {
    dispatch({ type: typeComplete(UNSELECT_COLLARS), payload: collarIds });
};

const dumpTableData = (payload: TableDownloadPayload) => (dispatch: any) => {
    dispatch({ type: typePending(DUMP_TABLE_DATA) });
    return drillholeShim.dumpTableData(payload).subscribe({
        next: (response: any) => {
            dispatch({ type: typeComplete(DUMP_TABLE_DATA), payload: response });
        },
        error: (error: any) => {
            dispatch({ type: typeFail(DUMP_TABLE_DATA), payload: { error } });
        },
    });
};

const setCurrentDrillhole = (id: string) => (dispatch: any, _getState: any) => {
    dispatch({ type: typeComplete(SET_CURRENT_DRILLHOLE), payload: id });
};

const clearDumpDataFile = () => (dispatch: any) => {
    dispatch({ type: typeComplete(CLEAR_DUMP_TABLE_DATA) });
};

const addDrillHole = (payload: any, type: ACTIVITY_TYPES) => (dispatch: any) => {
    dispatch({ type: typePending(ADD_DRILLHOLE) });
    return drillholeShim.addDrillHole(payload, type).subscribe({
        next: (response: AddDrillHoleResponse) => {
            const { Collar } = response;
            dispatch({
                type: typeComplete(ADD_DRILLHOLE),
                payload: { Collar },
            });
        },
        error: (error: any) => {
            dispatch({ type: typeFail(ADD_DRILLHOLE), payload: { error } });
        },
    });
};

const cloneDrillHole =
    (payload: CloneDrillholeRequest, type: ACTIVITY_TYPES) => (dispatch: any) => {
        dispatch({ type: typePending(CLONE_DRILHOLE) });
        return drillholeShim.cloneDrillHole(payload, type).subscribe({
            next: (response: Drillhole) => {
                dispatch({
                    type: typeComplete(CLONE_DRILHOLE),
                    payload: response,
                });
            },
            error: (error: any) => {
                dispatch({ type: typeFail(CLONE_DRILHOLE), payload: { error } });
            },
        });
    };

const exportCollars = (payload: any) => (dispatch: any) => {
    dispatch({ type: typePending(EXPORT_COLLAR) });
    return drillholeShim.exportCollars(payload).subscribe({
        next: (response: Drillhole) => {
            dispatch({
                type: typeComplete(EXPORT_COLLAR),
                payload: response,
            });
        },
        error: (error: any) => {
            dispatch({ type: typeFail(EXPORT_COLLAR), payload: { error } });
        },
    });
};

const deleteDrillHole = (drillholeId: string, type: ACTIVITY_TYPES) => (dispatch: any) => {
    dispatch({ type: typePending(DELETE_DRILLHOLE) });
    return drillholeShim.deleteDrillHole(drillholeId, type).subscribe({
        next: (response: any) => {
            dispatch({
                type: typeComplete(DELETE_DRILLHOLE),
                payload: response,
            });
        },
        error: (error: any) => {
            dispatch({ type: typeFail(DELETE_DRILLHOLE), payload: { error } });
        },
    });
};

const loadLabCertificateInfo = (labCerts: string[]) => (dispatch: any) => {
    dispatch({ type: typePending(LOAD_LAB_CERTIFICATE_INFO) });
    observableFrom(labCerts)
        .pipe(mergeMap((labCertId: string) => drillholeShim.loadLabCertificateInfo(labCertId), 2))
        .pipe(toArray())
        .subscribe({
            next: (response: LabCertificateInfo[]) => {
                dispatch({ type: typeComplete(LOAD_LAB_CERTIFICATE_INFO), payload: response });
            },
            error: (error: any) => {
                dispatch({ type: typeFail(LOAD_LAB_CERTIFICATE_INFO), payload: { error } });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
};

const unassignUserFromDrillhole =
    (drillholeId: string, type: ACTIVITY_TYPES, activityGroups: string[] = []) =>
    (dispatch: any) => {
        dispatch({ type: typePending(UNASSIGN_USER_FROM_DRILLHOLE) });
        return drillholeShim
            .unassignUserFromDrillhole(drillholeId, type, activityGroups)
            .subscribe({
                next: (response: any) => {
                    dispatch({
                        type: typeComplete(UNASSIGN_USER_FROM_DRILLHOLE),
                        payload: { response, drillholeId },
                    });
                },
                error: (error: any) => {
                    dispatch({ type: typeFail(UNASSIGN_USER_FROM_DRILLHOLE), payload: { error } });
                },
            });
    };

const clearUnassignUserState = (dispatch: any) => {
    dispatch({ type: CLEAR_UNASSIGN_USER_FROM_DHOLE_STATE });
};

const loadCoordinatesInfoFromDrillhole =
    (drillholeId: string, callAction: string, type: ACTIVITY_TYPES) =>
    (dispatch: any, getState: any) =>
        drillholeShim.loadDrillhole(drillholeId, type, DRILLHOLE_UPDATED_FIELDS).subscribe({
            next: (drillhole: Drillhole) => {
                dispatch({
                    type: typeComplete(LOAD_COORDINATES_TABLE),
                    payload: {
                        drillhole,
                        stateToUpdate: callAction,
                        buildColumns: false,
                        allState: getState(),
                    },
                });
            },
            error: (error: any) => {
                dispatch({ type: typeFail(callAction), payload: { error } });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });

const saveCoordinatesTable =
    (drillholeId: string, payload: any, rowReferenceId: string, type: ACTIVITY_TYPES) =>
    (dispatch: any, getState: any) => {
        dispatch({ type: typePending(SAVE_ROW_REFERENCES) });
        return drillholeShim
            .saveCoordinatesTable(drillholeId, payload, rowReferenceId, type)
            .subscribe({
                next: (_response: Row) => {
                    loadCoordinatesInfoFromDrillhole(
                        drillholeId,
                        SAVE_ROW_REFERENCES,
                        type,
                    )(dispatch, getState);
                },
                error: (error: any) => {
                    dispatch({ type: typeFail(SAVE_ROW_REFERENCES), payload: { error } });
                    dispatch({
                        type: ADD_SNACKBAR_NOTIFICATION,
                        payload: { type: NotificationType.ERROR, message: error },
                    });
                },
            });
    };

const dragCoordinates =
    (drillhole: Drillhole, type: ACTIVITY_TYPES) => (dispatch: any, getState: any) => {
        dispatch({ type: typePending(SAVE_ROW_REFERENCES) });
        return drillholeShim.saveDrillHole(drillhole, type).subscribe({
            next: (response: Drillhole) => {
                dispatch({
                    type: typeComplete(LOAD_COORDINATES_TABLE),
                    payload: {
                        drillhole: response,
                        allState: getState(),
                        buildColumns: false,
                        stateToUpdate: SAVE_ROW_REFERENCES,
                    },
                });
            },
            error: (error: any) => {
                dispatch({ type: typeFail(SAVE_ROW_REFERENCES), payload: { error } });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
    };

const loadCoordinatesTable = (drillhole: Drillhole) => (dispatch: any, getState: any) => {
    dispatch({ type: typePending(LOAD_ROW_REFERENCES) });
    // Need setTimeout to force AgGrid in DataGrid.tsx to unmount on coordinates table
    return setTimeout(
        () =>
            dispatch({
                type: typeComplete(LOAD_COORDINATES_TABLE),
                payload: {
                    drillhole,
                    allState: getState(),
                    buildColumns: true,
                    stateToUpdate: LOAD_ROW_REFERENCES,
                },
            }),
        300,
    );
};

const deleteCoordinatesTableRow =
    (drillholeId: string, rowReferenceId: string, type: ACTIVITY_TYPES) =>
    (dispatch: any, getState: any) => {
        dispatch({ type: typePending(DELETE_ROW_REFERENCES) });
        return drillholeShim
            .deleteCoordinatesTableRow(drillholeId, rowReferenceId, type)
            .subscribe({
                next: (_response: Row) => {
                    loadCoordinatesInfoFromDrillhole(
                        drillholeId,
                        DELETE_ROW_REFERENCES,
                        type,
                    )(dispatch, getState);
                },
                error: (error: any) => {
                    dispatch({ type: typeFail(DELETE_ROW_REFERENCES), payload: { error } });
                    dispatch({
                        type: ADD_SNACKBAR_NOTIFICATION,
                        payload: { type: NotificationType.ERROR, message: error },
                    });
                },
            });
    };

const loadExportTemplates = () => (dispatch: any) => {
    dispatch({ type: typePending(GET_CUSTOM_EXPORTS) });
    return drillholeShim
        .getCustomExports()
        .pipe(
            switchMap((customExports: CustomExportInfo[]) => {
                dispatch({
                    type: typeComplete(GET_CUSTOM_EXPORTS),
                    payload: customExports,
                });
                return drillholeShim.getExportTemplates();
            }),
        )
        .subscribe({
            next: (response: ExportTemplateInfo[]) => {
                dispatch({ type: typeComplete(GET_EXPORT_TEMPLATES), payload: response });
            },
            error: (error: any) => {
                dispatch({ type: typeFail(GET_EXPORT_TEMPLATES), payload: { error } });
                dispatch({ type: typeFail(GET_CUSTOM_EXPORTS), payload: { error } });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
};

// =================================================================
const customExport = (payload: any) => (dispatch: any) => {
    dispatch({ type: typePending(CUSTOM_EXPORT) });
    return drillholeShim.customExport(payload).subscribe({
        next: (response: any) => {
            dispatch({
                type: typeComplete(CUSTOM_EXPORT),
                payload: response,
            });
        },
        error: (error: any) => {
            dispatch({ type: typeFail(CUSTOM_EXPORT), payload: { error } });
        },
    });
};

const toggleSpan =
    (rowToToggle: { id: string; value: boolean }, isSample: boolean) =>
    (dispatch: any, getState: any) =>
        // isSample, used for passing down to row builder which performs validations accordingly
        dispatch({
            type: TOGGLE_SPAN,
            payload: { toggleInfo: rowToToggle, allState: getState(), isSample },
        });

const applyExternalFilter = (filter: string) => (dispatch: any) =>
    dispatch({
        type: APPLY_EXTERNAL_FILTER,
        payload: filter,
    });

const setFilterApplied = (filterApplied: boolean) => (dispatch: any) =>
    dispatch({
        type: FILTER_APPLIED,
        payload: filterApplied,
    });

const setSearchTerm = (value: string) => (dispatch: any) =>
    dispatch({
        type: SET_COLLAR_SEARCH_TERM,
        payload: value,
    });
// =================================================================

export const actions = {
    loadDrillholeWithCount,
    loadDrillholes,
    selectCollars,
    loadDrillhole,
    saveDrillHole,
    addDuplicateSample,
    loadRowReferences,
    clearRowReferenceResponse,
    clearFilesState,
    clearFilesForGroupLengthsState,
    clearSaveState,
    sortRowReferences,
    saveRowReference,
    copyCoordinateCells,
    copyCells,
    deleteRowReferences,
    fillDownSaveRowReference,
    copyIntervals,
    selectAllDrillHoles,
    clearAllDrillHoles,
    setCurrentDrillhole,
    addFileGroup,
    uploadFiles,
    getFilesForGroup,
    deleteFile,
    downloadFile,
    clearDownloadedFile,
    dumpTableData,
    clearDumpDataFile,
    clearEmptyFileGroup,
    createRows,
    addDrillHole,
    cloneDrillHole,
    clearAddDrillHoleState,
    clearCloneDrillHoleState,
    reIndex,
    deleteDrillHole,
    getEmptyFileGroups,
    saveSplitRowRowReference,
    mergeRowReferences,
    loadLabCertificateInfo,
    clearSaveRowReferenceState,
    clearDeleteDrillHoleState,
    unassignUserFromDrillhole,
    clearUnassignUserState,
    loadCoordinatesTable,
    saveCoordinatesTable,
    deleteCoordinatesTableRow,
    dragCoordinates,
    exportCollars,
    loadExportTemplates,
    customExport,
    clearExportCollarState,
    unSelectCollars,
    toggleSpan,
    applyExternalFilter,
    setFilterApplied,
    selectCollarByIdAndLoadData,
    setSearchTerm,
};
